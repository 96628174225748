import React from "react";
import { Link, graphql } from "gatsby";

import Fade from "react-reveal/Fade";

import { StaticImage } from "gatsby-plugin-image";

import LONDON from "../img/pages/company/cities/london.svg";
import NEW_YORK from "../img/pages/company/cities/new_york.svg";
import TORONTO from "../img/pages/company/cities/toronto.svg";

import ENERGY from "../img/pages/company/values/energy.svg";
import INNOVATION from "../img/pages/company/values/innovation.svg";
import PARTNERSHIP from "../img/pages/company/values/partnership.svg";
import PASSION from "../img/pages/company/values/passion.svg";
import VALUE from "../img/pages/company/values/value.svg";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import WereSocial from "../components/WereSocial";
import ComeGrowWithUs from "../components/ComeGrowWithUs";
import { BsArrowRight } from "react-icons/bs";

class Company extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout
        HeaderExtension={
          <div className="header_extension no_bg">
            <div className="bg">
              <div className="container aligncenter">
                <div className="columns post-single ui-grid">
                  <div className="column is-8">
                    <Fade bottom duration={1000} delay={125} distance={"25%"}>
                      <h1 className="primary heading_lg aligncenter">
                        <span>Your world-class beauty & wellness partner</span>
                      </h1>
                    </Fade>
                  </div>
                </div>

                <Fade bottom duration={1000} delay={250} distance={"25%"}>
                  <p style={{ marginTop: "1em" }}>
                    Building mega brands for over half a century.
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        }
        location={this.props.location}
        title={siteTitle}
      >
        <SEO
          title="Company"
          description="Learn about the Canadian soap company that grew to be one of the world’s largest beauty wholesale suppliers. An innovative leader in beauty production and cosmetic production."
          keywords={[
            "Beauty production, Beauty wholesale suppliers, Cosmetic production",
          ]}
          image={null}
        />

        <div>
          <div className="pages-index pages-company">
            <div className="section-content cp-locations">
              <div className="container">
                <div className="columns post-single ui-grid">
                  <div className="column is-4">
                    <img src={LONDON} alt="London" />
                  </div>

                  <div className="column is-4">
                    <img src={NEW_YORK} alt="New York" />
                  </div>

                  <div className="column is-4">
                    <img src={TORONTO} alt="Toronto" />
                  </div>
                </div>
              </div>
            </div>

            <div className="section-content cp-bio">
              <div className="cp-bg"></div>

              <div className="container">
                <div className="columns is-vcentered">
                  <div className="column is-6">
                    <h2>
                      Upper Canada was founded in 1969 with the goal of bringing
                      beautiful and high quality soap and beauty products to the
                      gift industry.
                    </h2>

                    <p>
                      Today, 53 years later our company is the leading innovator
                      of products in the bath, beauty and wellness sectors. Our
                      longstanding brands including Danielle, Erase Your Face,
                      Aroma Home among others are leaders in each of their
                      categories and consumers around the world are always
                      excited to see what is new from Upper Canada.
                    </p>

                    <p>
                      “I am proud of our team who work with me to create,
                      manufacture, sell and market our beautifully curated
                      brands to our customers in North America, Europe,
                      Australia and other countries. We always welcome new ideas
                      and suggestions from our employees, customers, and
                      consumers. Feel free to reach out to me at anytime,
                      pleasing all our stakeholders is my #1 goal.”
                    </p>

                    <p className="cp-cred">– Stephen Flatt, President</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-content">
              <div className="container">
                <div className="columns is-vcentered">
                  <div className="column is-6">
                    <StaticImage
                      src={"../img/pages/company/images/innovation.jpg"}
                      alt="Innovation is a beautiful thing"
                      className="img-with-bg"
                    />
                  </div>

                  <div className="column is-1 is-wide-only"></div>

                  <div className="column is-4">
                    <h2>Innovation is a beautiful thing</h2>

                    <p>
                      Nothing makes us more proud than delivering exciting and
                      highly anticipated products to market! Partnered with
                      leading suppliers and over 50 factories worldwide, we
                      manufacture the very best beauty & wellness products made
                      with high quality materials that undergo careful quality
                      control.
                    </p>

                    <p>
                      We’re continually adding to our highly curated roster of
                      brands, acquiring more opportunities to engage existing
                      customers, attract new customers, and widen our reach.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-content cp-quality">
              <div className="container">
                <div className="columns is-vcentered">
                  <div className="column is-5">
                    <h2>Quality matters</h2>

                    <p>
                      With development offices in Toronto, London and China, we
                      travel the globe in search of quality partnerships that
                      deliver:
                    </p>

                    <p>
                      <ul>
                        <li>
                          <BsArrowRight /> Superior manufacturing capabilities
                        </li>
                        <li>
                          <BsArrowRight /> Highly innovative research &
                          development
                        </li>
                        <li>
                          <BsArrowRight /> Exceptional quality control
                        </li>
                        <li>
                          <BsArrowRight /> Commitment to timing
                        </li>
                        <li>
                          <BsArrowRight /> Strict adherence to global protocols
                          and compliance standards
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-content">
              <div className="container">
                <div className="columns is-vcentered">
                  <div className="column is-6">
                    <StaticImage
                      src={"../img/pages/company/images/secrets.jpg"}
                      alt="We’re happy to share our secrets"
                      className="img-with-bg"
                    />
                  </div>

                  <div className="column is-1 is-wide-only"></div>

                  <div className="column is-4">
                    <h2>We’re happy to share our secrets</h2>

                    <p>
                      Our unwavering consumer trust coupled with our solid
                      business partnerships has contributed to our extensive
                      private label portfolio.
                    </p>

                    <p>
                      We offer years of experience and expertise across a
                      variety of complimentary categories in the bath, beauty &
                      wellness space, which continues to make us an exceptional
                      and preferred brand-building partner!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-content cp-global">
              <div className="container">
                <div className="columns is-vcentered">
                  <div className="column is-5">
                    <h2>We’re global</h2>

                    <p>
                      We've built strong manufacturing partnerships with
                      suppliers around the globe to source the best products
                      from multiple categories.
                    </p>

                    <p>
                      Our products and brands cater to all markets including
                      independent retailers, grocery & drug, department stores,
                      dot.com and everyone in between. With global distribution
                      and shipping from Canada, US, Europe, South America, Asia
                      and Australia!
                    </p>
                  </div>

                  <div className="column is-2 is-wide-only"></div>

                  <div className="column is-5">
                    <StaticImage
                      src={"../img/pages/company/images/were_global.jpg"}
                      alt="We’re global"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="section-content cp-culture">
              <div className="container">
                <h2>Our culture</h2>

                <div className="columns">
                  <div className="column">
                    <img src={INNOVATION} alt="Innovation" />

                    <h4>Innovation</h4>

                    <p>
                      We thrive on bringing fresh, distinctive ideas to our
                      ever-evolving categories
                    </p>
                  </div>

                  <div className="column">
                    <img src={PASSION} alt="Passion" />

                    <h4>Passion</h4>

                    <p>
                      We build brands that make people feel gorgeous, inside and
                      out, and we love what we do!
                    </p>
                  </div>

                  <div className="column">
                    <img src={ENERGY} alt="Energy" />

                    <h4>Energy</h4>

                    <p>
                      We’re nimble, on the pulse of evolving trends, and quick
                      to market
                    </p>
                  </div>

                  <div className="column">
                    <img src={VALUE} alt="Value" />

                    <h4>Value</h4>

                    <p>
                      We’re committed to offering high quality products at
                      affordable price points
                    </p>
                  </div>

                  <div className="column">
                    <img src={PARTNERSHIP} alt="Partnership" />

                    <h4>Partnership</h4>

                    <p>
                      We treat our business relationships as partnerships with
                      the goal of mutual success
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <WereSocial />

            <ComeGrowWithUs />
          </div>
        </div>
      </Layout>
    );
  }
}

export default Company;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
