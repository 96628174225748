import React from "react";
import { RiArrowRightUpLine } from "react-icons/ri";

const ComeGrowWithUs = ({ additionalClassName }) => (
  <div className={`cp-come-grow-with-us ${additionalClassName}`}>
    <div className="container aligncenter">
      <h2>Come grow with us!</h2>

      <p>
        Our company culture empowers the continuous growth of our team and every
        individual on it.
      </p>

      <a className="button" target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/jobs/search/?keywords=upper%20canada%20soap%20">
        View open roles <RiArrowRightUpLine />
      </a>
    </div>
  </div>
);

export default ComeGrowWithUs;
